<template>
  <div class="RelationFilters__filter has-text-left">
    <h3 class="is-size-4">
      {{ $t('components.relation_facts_filter.label') }}
      <span v-if="count">({{ count }})</span>
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.RelationFilters__filter {
  padding-bottom: $padding-small;
  margin-bottom: $margin;
  border-bottom: 2px solid $grey;
}
</style>
