<template>
  <div class="Recipient columns is-multiline">
    <div class="column is-3 is-offset-1 is-flex is-aligned-middle">
      <round-avatar
        :src="recipient.picture_url"
        :acronym-fallback="recipient.name"
        class="m-r-m"
        size="medium"
      />

      <h4 :title="recipient.name" class="has-ellipsis is-width-auto is-expanded">
        {{ recipient.name }}
      </h4>
    </div>

    <div v-if="!recipient.was_processed" class="column is-3 is-flex is-aligned-middle is-aligned-center">
      <div>{{ $t('pages.relations.facts.message_being_processed') }}</div>
    </div>
    <div v-else-if="!recipient.was_delivered" class="column is-3 is-flex is-aligned-middle is-aligned-center">
      {{ $t('pages.relations.facts.message_being_delivered') }}
    </div>
    <template v-else>
      <div v-if="recipient.opened_email" class="column is-3 is-flex is-aligned-middle is-aligned-center">
        {{ timeFromNow(recipient.opened_at) }} {{ $t('general.ago') }}
      </div>

      <div v-if="recipient.opened_link" class="column is-2 is-flex is-aligned-middle is-aligned-center">
        {{ timeFromNow(recipient.clicked_at) }} {{ $t('general.ago') }}
      </div>
    </template>
  </div>
</template>

<script>
import TimeFromNowMixin from '@hypefactors/shared/js/mixins/TimeFromNowMixin'

export default {
  mixins: [TimeFromNowMixin],

  props: {
    recipient: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Recipient {
  margin: 0 !important;

  h4 {
    font-weight: bold;
  }
}
</style>
