<template>
  <div
    ref="chart"
    class="email-event-percentage-chart"
  />
</template>

<script>
import { round } from '@/utils'
import DonutChart from '@hypefactors/shared/js/components/charts/base/DonutChart'
import { defaultColors } from '@hypefactors/shared/js/components/charts/DefaultChartColors'

/**
 * Chart used only on Relations and Relation Groups
 */
export default {
  props: {
    percentage: {
      type: Number,
      default: null
    },
    event: {
      type: String,
      default: 'open'
    },
    height: {
      type: String,
      default: '100px'
    }
  },

  data: () => ({
    $chart: null
  }),

  mounted () {
    this.$chart = this.initChart()
  },

  methods: {
    initChart () {
      const data = this.prepareData(this.percentage)

      return new DonutChart(this.$refs.chart, data.series, {
        title: data.title,
        height: this.height,
        yTitleOffset: 5
      })
    },

    prepareData (value) {
      const totalDisplayedPercentage = round(value * 100)
      const totalNotDisplayedPercentage = round(100 - (value * 100))

      const data = [
        {
          name: this.$t('components.charts.open_click.' + this.event),
          y: totalDisplayedPercentage
        },
        {
          name: this.$t('components.charts.open_click.not_' + this.event),
          y: totalNotDisplayedPercentage
        }
      ]

      return {
        title: totalDisplayedPercentage + '%',
        series: [{
          name: this.$t('forms.email'),
          colors: ['#c8471b', defaultColors[1]],
          data: data,
          showInLegend: false,
          dataLabels: {
            enabled: false,
            format: '{y} %'
          }
        }]
      }
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.email-event-percentage-chart {
  display: flex;
  justify-content: center;
  width: 100%;

  .highcharts-title {
    font-size: 1rem !important;
  }
}

</style>
