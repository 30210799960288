<template>
  <page-section
    class="RelationFacts"
    :page-title="$t('pages.relations.facts.title')"
    :page-tooltip="$t('pages.relations.facts.sidebar_title')"
  >
    <relation-facts-filter />

    <div class="Relation-facts pt-4">
      <heading size="5" color="black" :serif="false">
        {{ $t('pages.relations.facts.scheduled_emails') }}
      </heading>

      <load-more
        ref="scheduled_emails"
        :endpoint="scheduledEmailsUrl"
        :placeholder-items="2"
        width="column is-12"
        placeholder="RelationFactsItemPlaceholder"
      >
        <template
          slot="item"
          slot-scope="props"
        >
          <relation-fact-item
            :email="props.item"
            @refresh="$refs.scheduled_emails.refresh()"
          />
        </template>
      </load-more>

      <hr class="mt-6 mb-6">

      <heading size="5" color="black" :serif="false">
        {{ $t('pages.relations.facts.sent_emails') }}
      </heading>

      <div class="Relation-facts__header columns m-l-none m-r-none">
        <div class="column is-3 is-offset-1 p-b-none">
          <div class="label is-uppercase">
            {{ $t('pages.relations.facts.press_email_title') }}
          </div>
        </div>

        <div class="column is-3 p-b-none has-text-centered">
          <div class="label is-uppercase">
            {{ $t('pages.relations.facts.press_email_opened') }}
          </div>
        </div>

        <div class="column is-2 p-b-none has-text-centered">
          <div class="label is-uppercase">
            {{ $t('pages.relations.facts.press_email_clicked') }}
          </div>
        </div>

        <div class="column is-3 p-b-none">
          <div class="label is-uppercase">
            {{ $t('pages.relations.facts.press_email_audience') }}
          </div>
        </div>
      </div>

      <load-more
        ref="sent_emails"
        :endpoint="sentEmailsUrl"
        :placeholder-items="2"
        width="column is-12"
        placeholder="RelationFactsItemPlaceholder"
      >
        <template
          slot="item"
          slot-scope="props"
        >
          <relation-fact-item
            :email="props.item"
            @refresh="$refs.sent_emails.refresh()"
          />
        </template>
      </load-more>
    </div>
  </page-section>
</template>

<script>
import RelationFactsFilter from '@/components/connect/_filters/RelationFactsFilter'
import RelationFactItem from '@/components/connect/facts/RelationFactItem'

export default {
  name: 'RelationFactsIndex',

  components: {
    RelationFactItem,
    RelationFactsFilter
  },

  data: () => ({
    emails: []
  }),

  computed: {
    scheduledEmailsUrl () {
      return `/brands/${this.activeBrandId}/emails?type=story&sort=newest&whereScheduled=true`
    },

    sentEmailsUrl () {
      return `/brands/${this.activeBrandId}/emails?type=story&sort=newest&whereNotScheduled=true`
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.RelationFacts {
  .Relation-facts__header {
    // border-bottom: 1px solid $grey-lighter; // it's in the design, looks bad + not consistent with groups
  }
}

</style>
